.tms-report-container {
  width: 100%;
  padding: 10px;
  .tms-report-table {
    width: 100%;
    border: 1px solid black;
    border-collapse: collapse;
    td,
    tr {
      border: 1px solid black;
      font-size: 12px;
    }
    thead {
      td {
        font-weight: 700;
        font-size: 12px;
      }
    }
  }
}
.tms-absolute-button {
  position: absolute !important;
  margin-top: -58px;
  right: 15px;
}
.tms-modal-reports {
  text-align: center;
  display: flex;
  justify-content: center;
}
.tms-modal-div {
  width: 100%;
  display: flex;
  justify-content: center;
  .print-button {
    display: flex;
    align-items: center;
  }
}
.ta-center {
  text-align: center;
}
.tms-left-menu {
  background-color: white;
  // height: 100%;
  margin-right: 10px;
  p {
    margin: 0px;
    cursor: pointer;
    padding: 8px;
  }
  .tms-lm-active {
    background-color: #1890ff;
    color: white;
  }
}
.row-align {
  text-align: center;
  th {
    text-align: center !important;
    border-right: 0.5px solid rgb(239, 239, 239) !important;
    border-collapse: collapse !important;
  }
  tr {
    th {
      border-top: 0.5px solid rgb(239, 239, 239) !important;
    }
  }
  td {
    text-align: center;
  }
  .ant-table-thead
    > tr
    > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan]):before {
    display: none;
  }
}
.countDownOTP {
  span {
    font-size: 14px !important;
  }
}
@page {
  margin: 0.5cm;
}
@media print {
  @page {
    margin: 0.5cm;
  }
  td,
  th {
    font-size: 12px;
  }
  .tms-report-container {
    width: 100%;
    .tms-report-table {
      width: 100%;
      border: 1px solid black;
      border-collapse: collapse;
      td,
      tr {
        border: 1px solid black;
        font-size: 12px;
        text-align: center;
      }
      thead {
        th {
          border: 1px solid black;
          font-weight: 700;
          font-size: 12px;
          padding: 3px;
        }
      }
    }
  }
  .ta-center {
    text-align: center;
  }
  .al {
    text-align: left !important;
    padding-left: 5px;
  }
}
