.d-flex-c {
  display: flex;
  flex-direction: column;
  label {
    text-align: left;
  }
  .tms-login-button {
    background-color: #e11513;
    color: white;
  }
  .mobile-field {
    width: 100%;
    text-align: left;
    .ant-int-group {
      margin: 10px 0px !important;
    }
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
}
.tms-calender {
  width: 100%;
  display: flex;
  justify-content: center;
  .calender-inner {
    padding: 10px 20px;
  }
}
.cal-select {
  text-align: left;
  margin-bottom: 3px;
}
.events {
  margin: 0;
  padding: 0;
  list-style: none;
}
.events .ant-badge-status {
  width: 100%;
  overflow: hidden;
  font-size: 12px;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.notes-month {
  font-size: 28px;
  text-align: center;
}
.notes-month section {
  font-size: 28px;
}
.antd-custom {
  max-height: 150px;
  min-height: 150px;
  height: 100%;
}
.antd-custom .ant-card-body {
  align-items: center;
  display: flex;
  justify-content: flex-start;
  border-radius: 5px !important;
  height: 100% !important;
  flex-direction: column;
}
.antd-custom:hover {
  // box-shadow: 1px 2px 14px -4px !important;
  font-weight: 700;
}

.ant-statistic {
  flex-direction: column-reverse;
  display: flex;
}
.ant-statistic-content {
  font-size: 17px !important;
  font-weight: 600;
}

.sd-select-r {
  width: 250px;
}
.sd-select-dropdown-r {
  min-width: 200px !important;
  max-width: 370px;
}
.w-100 {
  width: 100%;
}
.ant-upload.ant-upload-select {
  width: 100%;
}
