.sd-color-red {
  background-color: rgb(248, 137, 137);
  font-weight: 600;
}
.sd-color-orange {
  background-color: rgb(245, 182, 124);
  font-weight: 600;
}
.accomadation_table .ant-table-row .ant-table-cell {
  padding: 5px;
}
.checkout-table {
  .ant-pagination {
    margin: 20px 30px !important;
  }
}
.accomadation_table .ant-table-row:nth-child(even) {
  background-color: aliceblue;
}
.col-padding {
  padding: 0px 2px !important;
}
.sd-light-orange {
  background-color: rgb(240, 227, 216);
  font-weight: 600;
}
.sd-dark-red {
  background-color: #fd4b4b;
  font-weight: 600;
}
.sd-light-orange:hover .optioncss {
  color: orange;
}
.sd-light-orange:hover .optioncss {
  color: orange;
}
.sd-color-red:hover .optioncss {
  color: orange;
}
.optioncss {
  color: black;
}
.optioncss:hover {
  color: orange;
}
.ant-statistic-title {
  font-size: 12px !important;
}
.ant-statistic-content {
  font-size: 20px !important;
}
.acc-cards {
  border: 1.5px solid #cbcbcb !important
  ;
  margin-bottom: 20px !important;
  box-sizing: border-box;
  .ant-card-head {
    padding: 0px 10px;
    .ant-card-head-title {
      padding: 10px 0px;
    }
  }
  .ant-card-body {
    padding: 15px 20px;
  }
}
.acc-status-rooms {
  border-radius: 15px;
  padding: 2px 10px;
  margin: 0px 5px 10px 0px;
  cursor: pointer;
}
.green {
  background: green;
  color: white;
}
.green-ot {
  border: 1px solid green;
  color: green;
}
.occupancy {
  display: flex;
  border-top: 1.5px solid #cbcbcb;
  padding-top: 10px;
  margin-top: 5px;
  .o-inner {
    width: 50%;
    &:first-child {
      padding-right: 5px;
    }
    &:last-child {
      padding-left: 5px;
    }
    .o-i-inner {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      padding: 0px;
      // p {
      //   width: 35%;
      //   text-align: left;
      //   font-size: 15px;
      //   font-weight: 600;
      // }
      .oii-inner {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        .percentage-skill {
          width: 30%;
          font-size: 10px;
          color: grey;
        }
        .skill-container {
          width: 60%;
          background-color: rgb(192, 192, 192);
          height: 3px;
          display: flex;
          align-items: center;

          border-radius: 15px;
          p {
            font-size: 10px;
            color: grey;
            width: 20%;
          }
          .skill {
            height: 3px;
            background-color: darkgreen;
            color: white;
            padding: 1px;
            text-align: right;
            font-size: 8px;
            border-radius: 15px;
          }
        }
      }
    }
    p {
      margin: 0px;
      font-size: 11.5px;
      padding-left: 0px !important;
      text-align: left;
    }
    &:first-child {
      border-right: 1.5px solid #cbcbcb;
    }
  }
}

.blue {
  background: rgb(34, 109, 230);
  color: white;
}
.roomCount .ant-card-body {
  padding: 2px !important;
  padding-bottom: 10px !important;
}
.maintanence {
  border-top: 1.5px solid #cbcbcb;
  padding-top: 10px;
  margin-top: 10px;
}
.color-red .ant-statistic-content .ant-statistic-content-value {
  color: red !important;
}
.sd-large .ant-statistic-content .ant-statistic-content-value {
  color: orange !important;
  font-size: 24px;
}
