.formLabel {
  width: 20%;
  text-align: right;
  flex-direction: column;
  display: flex;
  justify-content: center;
  padding-right: 20px;
}
.tms-prasadam-inventory {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .max-80 {
    max-width: 80%;
  }
}

.formInput {
  width: 80% !important;
}

.formCols-1 {
  width: 26% !important;
}
.pr-0 {
  padding-right: 0px !important;
}
.pl-0 {
  padding-left: 0px !important;
}

.formCols-2 {
  width: 39% !important;
}

.formRow {
  padding-bottom: 20px;
}

.contantCenter {
  justify-content: center;
  display: flex;
  align-content: center;
  flex-wrap: wrap;
}

.formButton {
  width: 100%;
}

.buttonPad {
  padding-left: 4px !important;
  padding-right: 15px !important;
}
