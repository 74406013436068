.roomsTable {
  .ant-table-content {
    overflow-x: hidden !important;
  }
  th,
  tr {
    text-align: center !important;
  }
}

.uploadDoc {
  .ant-upload {
    width: 100%;
  }
}

.CountryRegionDropDown {
  border: 1px solid #d9d9d9;
  height: 32px;
  font-size: 14px;
  padding: 0px 8px;
  width: 100%;
  border-radius: 2px;
}

.RoomCard {
  .ant-card-body {
    padding: 0 !important;
  }
}
