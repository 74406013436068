.tms-outer-wrapper {
  width: 100%;
  height: 100vh;
  display: flex;
  position: relative;
  .tms-left {
    width: 70%;
    height: 100%;
    // background-image: url("../../images/SD-gallery-05.png");
    background-position: right;
    background-size: cover;
    .tms-carousel {
      width: 100%;
      height: 100%;
      .tms-carousel-slide {
        position: relative;
        z-index: 1;
        .tms-carousel-inner-text {
          position: absolute;
          z-index: 2;
          bottom: 10px;
          margin-left: 50px;
          color: white;
          border-left: 2px solid white;
          padding-left: 10px;
          font-size: large;
        }
      }
    }
  }
  .tms-right {
    width: 30%;
    display: flex;
    flex-direction: column;
    align-items: center;
    .tms-logo {
      margin-top: 20%;
      height: 80px;
      margin-bottom: 10px;
    }
    .tms-h2 {
      font-weight: 700;
      margin-bottom: 30px;
    }
    .tms-back-arrow-text {
      color: blue;
    }
    .tms-reserved {
      position: absolute;
      bottom: 10px;
    }
  }
}
