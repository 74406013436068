.bookingTable {
  counter-reset: Serial; /* Set the Serial counter to 0 */
}

.bookingTable table {
  border-collapse: separate;
}

.bookingTable tr td:first-child:before {
  counter-increment: Serial; /* Increment the Serial counter */
  content: counter(Serial); /* Display the counter */
}

.report {
  width: 100%;
}

.cardDiv .contantfontsize {
  font-size: 14px;
}
//add
.mt-10 {
  margin-top: 10px;
}
.headercol {
  margin-top: 5px !important;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.header-2 {
  justify-content: center !important;
  margin: auto !important;
  width: 200px !important;
  text-align: center;
  text-transform: capitalize;
  font-size: 11px;
}
.border-dash {
  border-top: 1px solid black;
  height: 1px;
  margin: 2px 0px;
}
.mb-0 {
  margin-bottom: 0px;
}

.cardDiv .ant-card-body {
  padding: 15px 18px;
}
.cardDiv .top-date {
  font-size: 10px;
  float: right;
}

.cardDiv .top-id {
  font-size: 10px;
  float: left;
}

.bookingTable,
.reportTable {
  text-align: left;
  width: 100%;
  border: 1px solid black;
  margin-bottom: 20px;
  text-transform: capitalize;
}

.bookingTable .tableData,
.bookingTable .tableData {
  border: 1px solid black;
  width: 5%;
}

.bookingTable .serialNo {
  width: 5%;
  border: 1px solid black;
}

.bookingTable .slno {
  width: 8%;
}

.bookingTable .collection {
  width: 8%;
}

.bookingTable .itemName {
  width: 20%;
  border: 1px solid black;
  word-wrap: break-word;
}

.reportTable th,
.reportTable td {
  width: 50%;
  border: 1px solid black;
}

td.itemName {
  font-size: 12px;
  word-wrap: break-word;
}

.cardDiv {
  background-color: white !important;
  color: black !important;
}

.cardDiv .headerrow,
.footerContent {
  justify-content: center !important;
  margin: auto !important;
  width: 100% !important;
  text-align: center;
  text-transform: capitalize;
}
.footerContent-bottom {
  width: 100% !important;
  display: flex;
  justify-content: space-between;
}
.headerrow {
  p {
    margin-bottom: 5px;
  }
}

.cardDiv h1,
h2,
h3 {
  color: black !important;
}

.cardDiv .ant-row .boldText {
  font-weight: bold;
}

.cardDiv .ant-divider-dashed {
  border-color: black;
  border-width: 2px 0 0;
  margin: 0 !important;
}

.cardDiv .ant-divider-horizontal {
  margin-bottom: 15px !important;
}

.cardDiv .col-6-head {
  width: 49%;
  text-align: right;
  margin-right: 4px;
}

.cardDiv .col-6-body {
  width: 50%;
  text-align: left;
}

.emptyCell {
  visibility: hidden;
}

@media print {
  .sd-report-container {
    .report {
      width: 100%;
    }
    // .reportFooter {
    //   page-break-after: always;
    // }
    .bookingTable,
    .reportTable {
      text-align: left;
      width: 100%;
      border: 1px solid black;
      margin-bottom: 30px;
      text-transform: capitalize;
      font-size: 14px;
    }
    .mt-10 {
      margin-top: 10px;
    }
    .headercol {
      margin-top: 5px !important;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
    .header-2 {
      justify-content: center !important;
      margin: auto !important;
      width: 200px !important;
      text-align: center;
      text-transform: capitalize;
      font-size: 14px;
    }
    .border-dash {
      border-top: 1px solid black;
      height: 1px;
      margin: 2px 0px;
    }
    .mb-0 {
      margin-bottom: 0px;
    }

    .cardDiv .contantfontsize {
      font-size: 14px;
    }
    .cardDiv .top-date {
      font-size: 10px;
      float: right;
    }

    .cardDiv .ant-card-body {
      padding: 5px 14px;
    }

    .bookingTable .tableData,
    .bookingTable .tableData {
      border: 1px solid black;
      width: 5%;
    }

    .bookingTable .serialNo {
      width: 5%;
      border: 1px solid black;
    }

    .bookingTable .slno {
      width: 8%;
    }

    .bookingTable .collection {
      width: 8%;
    }

    .bookingTable .itemName {
      width: 20%;
      border: 1px solid black;
      word-wrap: break-word;
    }

    .reportTable th,
    .reportTable td {
      width: 50%;
      border: 1px solid black;
    }

    td.itemName {
      font-size: 14px;
      word-wrap: break-word;
    }

    .cardDiv {
      background-color: white !important;
      color: black !important;
    }

    .cardDiv .headerrow,
    .footerContent {
      justify-content: center !important;
      margin: auto !important;
      width: 100% !important;
      text-align: center;
      text-transform: capitalize;
    }

    .cardDiv h1,
    h2,
    h3 {
      color: black !important;
    }

    .cardDiv .ant-row .boldText {
      font-weight: bold;
    }

    .cardDiv .ant-divider-dashed {
      border-color: black;
      border-width: 2px 0 0;
      margin: 0 !important;
    }

    .cardDiv .ant-divider-horizontal {
      margin-bottom: 15px !important;
    }

    .cardDiv .col-6-head {
      width: 49%;
      text-align: right;
      margin-right: 4px;
    }

    .cardDiv .col-6-body {
      width: 50%;
      text-align: left;
    }
  }
}
